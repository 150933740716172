import React from 'react'
import { Link } from 'react-router-dom'

const DetailsTadobaNational = () => {
    return (
        <>
            <div class="container-fluid bg-goa">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h3 class="text-white display-3 mb-4">Tadoba National Park</h3>
                    <ol class="breadcrumb justify-content-center mb-0">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="breadcrumb-item active text-white">Tadoba National Park</li>
                    </ol>
                </div>
            </div>
            <div class="container-fluid booking py-5">
                <div class="container py-5">
                    <div class="row g-5 align-items-center">
                        <div class="col-sm-6">
                            <h5 class="section-booking-title pe-3">Tadoba National Park Tour</h5>
                            <h1 class="text-white mb-4">Tadoba National Park Tour Package</h1>
                            <p class="text-white mb-4"><i className='fa fa-calendar'></i> &nbsp; 2 Nights  3 Days</p>
                            <p><b>Destination Covered :  </b>Nagpur</p>
                            <p class="text-white mb-4"><b>Tour Activities : </b>Jungle Safari, Jeep Safari</p>
                            <p class="text-white mb-4"><b>Tour Themes : </b>Wildlife</p>
                            <p class="text-white mb-4">
                                Tadoba - Andhari Tiger Reserve is the pristine and unique eco system in the chandrapur district of the Nagpur, Maharashtra state of India. The Reserve contains some of the best of forest tracks and endowed with rich biodiversity. It is famous for its natural heritage .Today, Tadoba – Andhari Tiger Reserve (TATR ) is the second Tiger Reserve in the state after Melghat tiger Reserve. The area of the Reserve is 625.Sq.Kms. This includes Tadoba National Park, created in 1955 with an area of 116.55 sq.kms and Andhari wildlife Sanctuary created in 1986 with an area of 508.85 sq.kms. The Reserve also includes. 32.51 Sq.kms. of protected forest and 14.93 sq.km of other areas.
                            </p>
                            <div class="accordion" id="accordionPanelsStayOpenExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                            Day 1 : Arrive Nagpur –Tadoba National Park (140 kms 03.5 hours drive )
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                        <div class="accordion-body">
                                            <p className='text-primary'>
                                                <ul>
                                                    <li>Arrival Transfer</li>
                                                    <li>Hotel Stay </li>
                                                    <li>Lunch</li>
                                                    <li>Dinner</li>
                                                    <li>Other Benefits ( on Arrival ) </li>
                                                    <li>Breakfast</li>
                                                    <li>Transfers </li>
                                                    <li>Stay included </li>
                                                </ul>
                                                <p className='mt-2'>On arrival at Nagpur Railway Station or Airport, our Representative will meet and Transfer you to Tadoba upon arrival, check into the wildlife resort,later freshen up And enjoy a Scrumptious lunch. There after in the evening you are free to take Pleasure in a mature walk, followed by dinner and an overnight stay in the resort</p>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                            Day 2 : Tadoba National Park
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                        <div class="accordion-body">
                                            <p className='text-primary'>
                                                <ul>
                                                    <li>Breakfast</li>
                                                    <li>Sightseeing</li>
                                                    <li>Tour Hotel</li>
                                                    <li>Stay Dinner</li>
                                                </ul>
                                                <p className='mt-2'>Early in the morning the jeep will pick you up from the resort for the Jungle Safari. It is the best time to sight various birds stretching their wings high in the Sky ,Besides the birds, various other wild animal will be waiting to greet you. After The jungle safari return to the resort for breakfast .Enjoy the breakfast, then lunch And then again head off for an evening jeep safari into the National Park. In the Evening you have more chances of sighting tigers.</p>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                            Day 3 : Tadoba National Park - Nagpur Departure ( 140 kms )
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                        <div class="accordion-body">
                                            <p className='text-primary'>
                                                <ul>
                                                    <li>Breakfast</li>
                                                    <li>Departure Transfer</li>
                                                </ul>
                                                <p>Getup early in the morning for the last jeep Safari in the park ( the jeep will Pick you up from the resort ) After witnessing the wonderful world of the Wild beings, return back to the resort, Later, treat your taste buds with the Healthy and yummy breakfast and then pack your bags for check out. Drive to Nagpur and upon arrival . we will drop you to the airport ,railway Station or your preferred hotel.</p>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Link to="/Book-Now" class="btn btn-light text-primary rounded-pill py-3 px-5 mt-2">Book Now</Link>
                                </div>
                                <div className="col pt-4 text-end">
                                    <Link to="/Canecellation-Policy" className='text-white'>Cancellation policy</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <img src="/img/taboda.jpg" height="100%" width="100%" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsTadobaNational