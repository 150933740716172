import React from 'react'
import { Link } from 'react-router-dom'

const DetailsGoa = () => {
    return (
        <>
            <div class="container-fluid bg-goa">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h3 class="text-white display-3 mb-4">Goa Tour</h3>
                    <ol class="breadcrumb justify-content-center mb-0">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="breadcrumb-item active text-white">Goa Tour</li>
                    </ol>
                </div>
            </div>
            <div class="container-fluid booking py-5">
                <div class="container py-5">
                    <div class="row g-5 align-items-center">
                        <div class="col-sm-6">
                            <h5 class="section-booking-title pe-3">Goa Tour</h5>
                            <h1 class="text-white mb-4">Goa Tour Package</h1>
                            <p class="text-white mb-4"><i className='fa fa-calendar'></i> &nbsp; 2 Nights 3 Days</p>
                            <p class="text-white mb-4"><b>Destination Covered :  </b>Goa</p>
                            <p class="text-white mb-4"><b>Tour Activities : </b>Beaches Sightseeing and water Activities</p>
                            <p class="text-white mb-4"><b>Tour Themes : </b>Beaches and Islands</p>
                            <p class="text-white mb-4">Goa is visited by large numbers of international and domestic tourists each year because of its white-sand beaches, active nightlife, places of worship, and World Heritage-listed architecture.</p>
                            <div class="accordion" id="accordionPanelsStayOpenExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                            Day 1
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                        <div class="accordion-body">
                                            <p className='text-primary'>Upon your arrival at the Goa airport/ railway station, your 3 days trip to Goa has started. Our tour representative assists you in smooth transfer to the hotel for a pre-booked stay. The first day of your Goa trip will acquaint you with the rich Portuguese culture and heritage of this tiny Indian state. There are several remnants in Old Goa in the form of century-old cathedrals such as Basilica of Bom Jesus and Chapel of St. Francis Xavier. Goa is also known for its massive forts which were built by Indian rulers and Portuguese colonies which settled in Goa in the 15th century. Besides, you can visit the famous 'Fort Aguada' whose major portions have been converted into a luxury hotel. Post sightseeing, return to the hotel for dinner and night stay.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                            Day 2
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                        <div class="accordion-body">
                                            <p className='text-primary'>The 2nd day of your 3 days tour to Goa is dedicated to the exploration of North Goa beaches. The charm of Goa beaches lies in its beautiful beaches lined on the seashore and attracts a huge crowd of Indian and international tourists. Some popular beaches that you can visit include Anjuna, Baga, and Calangute. These beaches will allow you to indulge in water sports such as Parasailing, scuba diving, and water skiing. Besides, you can visit the local markets at Anjuna where you can purchase traditional jewelry, handicrafts, and souvenirs. The 2nd day of your Goa Trip will make you experience the nightlife of Goa. Proceed towards the Tito's bar, one of the most happening party places in Goa to enjoy the vibrant nightlife. Besides, you can also visit the Ingo's Saturday Night Bazaar famous for shopping. Dinner and overnight stay in Goa.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                            Day 3
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                        <div class="accordion-body">
                                            <p className='text-primary'>On the 3rd day of your Goa Trip, you will head straight towards South Goa. Colva is a popular beach in South Goa. The beach is home to shacks, resort complexes, tourist cottages, discos, and eateries. Have some fun and laze around as the day will mark the end of your Goa 3 day trip plan. You will be assisted in smooth transfer to the Goa airport/railway station to board flight/train for onwards journey.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Link to="/Book-Now" class="btn btn-light text-primary rounded-pill py-3 px-5 mt-2">Book Now</Link>
                                </div>
                                <div className="col pt-4 text-end">
                                    <Link to="/Canecellation-Policy" className='text-white'>Cancellation policy</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <img src="/img/goa-2.jpg" height="100%" width="100%" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsGoa