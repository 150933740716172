import React from 'react'
import { Link } from 'react-router-dom'

const Gallery = () => {
    return (
        <>
            <div class="container-fluid bg-goa">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h3 class="text-white display-3 mb-4">Our Gallery</h3>
                    <ol class="breadcrumb justify-content-center mb-0">
                        <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li class="breadcrumb-item"><Link to="/">Pages</Link></li>
                        <li class="breadcrumb-item active text-white">Our Gallery</li>
                    </ol>
                </div>
            </div>
            <div class="container-fluid gallery py-5 my-5" id='gallery'>
                <div class="mx-auto text-center mb-5" style={{ maxWidth: ' 900px' }}>
                    <h5 class="section-title px-3">Our Gallery</h5>
                    <h1 class="mb-4">Tourism & Traveling Gallery.</h1>
                    <p class="mb-0">
                        Welcome to our Tourism & Traveling Gallery, where wanderlust meets inspiration. Discover a curated collection of stunning visuals and stories that capture the essence of the world's most captivating destinations. From serene landscapes and vibrant cityscapes to hidden gems and cultural marvels, our gallery invites you to explore the beauty and diversity of global travel. Immerse yourself in the richness of each locale, as we celebrate the joy of discovery and the magic of new experiences. Whether you're seeking your next adventure or simply indulging in a virtual escape, our gallery promises to ignite your passion for travel and broaden your horizons.
                    </p>
                </div>
                <div class="tab-class text-center">
                    <ul class="nav nav-pills d-inline-flex justify-content-center mb-5">
                        <li class="nav-item">
                            <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill active" data-bs-toggle="pill" href="#GalleryTab-1">
                                <span class="text-dark" style={{ width: '150px' }}>All</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="d-flex py-2 mx-3 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-2">
                                <span class="text-dark" style={{ width: '150px' }}>Kullu-Manali</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-3">
                                <span class="text-dark" style={{ width: '150px' }}>Rajasthan</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-4">
                                <span class="text-dark" style={{ width: '150px' }}>Gujrat</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-Goa">
                                <span class="text-dark" style={{ width: '150px' }}>Goa</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="GalleryTab-1" class="tab-pane fade show p-0 active">
                            <div class="row g-2">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan.jpg" data-lightbox="gallery-1" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-2.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/mehrangarh-fort-rajasthan.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/mehrangarh-fort-rajasthan.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/kullu-manali.png" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/kullu-manali.png" data-lightbox="gallery-4" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/goa.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/goa.jpg" data-lightbox="gallery-5" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/kullu-manali-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/kullu-manali-2.jpg" data-lightbox="gallery-6" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-jodhpur.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-jodhpur.jpg" data-lightbox="gallery-7" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/taboda-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/taboda-2.jpg" data-lightbox="gallery-8" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/taboda.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/taboda.jpg" data-lightbox="gallery-9" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/gallery-10.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/gallery-10.jpg" data-lightbox="gallery-10" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="GalleryTab-2" class="tab-pane fade show p-0">
                            <div class="row g-2">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/kullu-manali.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Kullu Manali Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/kullu-manali.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/kullu-manali.png" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Kullu Manali Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/kullu-manali.png" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/kullu-manali-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Kullu Manali Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/kullu-manali-2.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="GalleryTab-3" class="tab-pane fade show p-0">
                            <div class="row g-2">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-3.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-3.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-2.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-4.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-4.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-5.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-5.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/mehrangarh-fort-rajasthan.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/mehrangarh-fort-rajasthan.jpgg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-jodhpur.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-jodhpur.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="GalleryTab-4" class="tab-pane fade show p-0">
                            <div class="row g-2">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Gujrat-3.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Gujrat Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Gujrat-3.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Gujrat-4.jpeg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Gujrat Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Gujrat-4.jpeg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Gujrat-5.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Gujrat Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Gujrat-5.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Gujrat-6.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Gujrat Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Gujrat-6.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Gujrat-7.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Gujrat Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Gujrat-7.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="GalleryTab-Goa" class="tab-pane fade show p-0">
                            <div class="row g-2">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/goa.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Goa Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/goa.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/goa-3.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Goa Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/goa-3.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/goa-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Goa Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/goa-2.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/goa-5.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Goa Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/goa-5.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gallery