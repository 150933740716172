import React from 'react'
import { Link } from 'react-router-dom'
const DetailsRajasthan = () => {
    return (
        <>
            <div class="container-fluid bg-goa">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h3 class="text-white display-3 mb-4">Rajasthan Tour</h3>
                    <ol class="breadcrumb justify-content-center mb-0">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="breadcrumb-item active text-white">Rajasthan Tour</li>
                    </ol>
                </div>
            </div>
            <div class="container-fluid booking py-5">
                <div class="container py-5">
                    <div class="row g-5 align-items-center">
                        <div class="col-sm-6">
                            <h5 class="section-booking-title pe-3">Rajasthan Tour</h5>
                            <h1 class="text-white mb-4">Rajasthan Tour Package</h1>
                            <p class="text-white mb-4"><i className='fa fa-calendar'></i> &nbsp; 2 Nights 3 Days</p>
                            <p class="text-white mb-4"><b>Destination Covered :  </b>Jaipur, Udaipur, Jaigarh Fort, Hawa Mahal, Gaitor, Jagdish Temple, Bhartiya Lok Kala Museum, Lake Pichola, Gulab Bagh, Amber Fort, City Palace</p>
                            <p class="text-white mb-4"><b>Tour Activities : </b>Forts and Palaces, Museums</p>
                            <p class="text-white mb-4"><b>Tour Themes : </b>Culture & Heritage</p>
                            <p class="text-white mb-4">Rajasthan, often celebrated as the "Land of Kings," is a captivating state in northwestern India renowned for its regal heritage and vibrant culture. With its arid landscapes punctuated by majestic forts, opulent palaces, and sprawling deserts, Rajasthan offers a glimpse into India's rich history and architectural splendor. The state's capital, Jaipur, known as the "Pink City," boasts magnificent structures like the Hawa Mahal and the Amber Fort, while Udaipur, the "City of Lakes," enchants visitors with its serene water palaces and lush gardens.
                            </p>
                            <div class="accordion" id="accordionExample">
                                {/* <!-- Day 1 --> */}
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Day 1: Jaipur Airport / Railway Station to Hotel
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                Meet & Greet on arrival at Jaipur Railway Station / Airport and transfer to the hotel. Check-in to the hotel. In the evening, you can opt for a tour to visit Laxminarayan Temple (Birla Mandir) and Chokhi Dhani – An Ethnic Village Resort. Return to the hotel for an overnight stay in Jaipur.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Day 2 --> */}
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Day 2: Jaipur Local Sightseeing
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                After breakfast, start a full day of local sightseeing in Jaipur. Visit Amber Fort & Palace, Gaitore Cenotaphs (Front View), take a photo stop at Jal Mahal, Jaigarh Fort, City Palace & Museum, Jantar Mantar (Observatory), and take a photo stop at Hawa Mahal. Evening free for leisure. Overnight stay in Jaipur.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Day 3 --> */}
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Day 3: Inter Hotel/City Transfer from Jaipur to Udaipur
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                After breakfast, transfer to Udaipur. En route, visit the Ranakpur Chaumukha Temple, built in 1439. This marble temple has 29 halls supported by 1444 pillars. On arrival, check-in to your hotel. In the evening, enjoy a boat tour on the picturesque Lake Pichola (at your own cost) to admire the royal buildings around the lake and enjoy the scenic sunset view. From the lake, enjoy a wonderful view of the City Palace stretching along the east bank, while the south bank has pleasant gardens running down to the waterside. Overnight stay in Udaipur.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Day 4 --> */}
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Day 4: Udaipur Local Sightseeing
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                After breakfast, start your Udaipur city tour. Visit Fateh Sagar, Maharana Pratap Memorial, take a photo stop at Nehru Garden, Sehelion-Ki-Bari – Queen’s resort for her friends, Sukhadia Circle (Drive Pass), Bhartiya Lok Kala Museum – a museum of folk and art, displaying a rich collection of folk dresses, ornaments, puppets, masks, and dolls. Later, visit the City Palace – the largest palace complex in Rajasthan, Jagdish Temple – dedicated to Lord Vishnu, and Gulab Bagh. Overnight stay in Udaipur.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Link to="/Book-Now" class="btn btn-light text-primary rounded-pill py-3 px-5 mt-2">Book Now</Link>
                                </div>
                                <div className="col pt-4 text-end">
                                    <Link to="/Canecellation-Policy" className='text-white'>Cancellation policy</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <img src="/img/Rajasthan-2.jpg" height="100%" width="100%" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsRajasthan