import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <>
            <div class="container-fluid bg-primary px-5 d-none d-lg-block">
                <div class="row gx-0">
                    <div class="col-lg-6 text-center text-lg-start mb-2 mb-lg-0">
                        <div class="d-inline-flex align-items-center" style={{ height: '45px' }}>
                            <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-twitter fw-normal"></i></a>
                            <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-facebook-f fw-normal"></i></a>
                            <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-linkedin-in fw-normal"></i></a>
                            <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-instagram fw-normal"></i></a>
                            <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href=""><i class="fab fa-youtube fw-normal"></i></a>
                        </div>
                    </div>
                    
                </div>
            </div>        
            <div class="container-fluid position-relative p-0">
                <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                    <a href="" class="navbar-brand p-0">
                        {/* <h1 class="m-0"><i class="fa fa-plane me-3"></i>BHARAT TOURISM</h1> */}
                        <img src="/img/logo(1).png" alt="Logo" width='100px'/>
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span class="fa fa-bars"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarCollapse">
                        <div class="navbar-nav ms-auto py-0">
                            <Link to="/" class="nav-item nav-link active">Home</Link>
                            <Link to="/about" class="nav-item nav-link">About</Link>
                            <Link to="/services" class="nav-item nav-link">Services</Link>
                            <Link to="/packages" class="nav-item nav-link">Packages</Link>
                            <Link to="/gallery" class="nav-item nav-link">Gallery</Link>
                            {/* <Link to="/contact-us" class="nav-item nav-link">Blog</Link> */}
                            {/* <div class="nav-item dropdown">
                                <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                                <div class="dropdown-menu m-0">
                                    <a href="destination.html" class="dropdown-item">Destination</a>
                                    <a href="tour.html" class="dropdown-item">Explore Tour</a>
                                    <a href="booking.html" class="dropdown-item">Travel Booking</a>
                                    <a href="gallery.html" class="dropdown-item">Our Gallery</a>
                                    <a href="guides.html" class="dropdown-item">Travel Guides</a>
                                    <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                                    <a href="404.html" class="dropdown-item">404 Page</a>
                                </div>
                            </div> */}
                            <Link to="/contact-us" class="nav-item nav-link">Contact</Link>
                        </div>
                        <Link to="/Book-Now" class="btn btn-primary rounded-pill py-2 px-4 ms-lg-4">Book Now</Link>
                    </div>
                </nav>
                

            </div>    
        </>
    )
}

export default Header