import React from 'react'

const Services = () => {
    return (
        <>
            <div class="container-fluid bg-goa">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h3 class="text-white display-3 mb-4">Services</h3>
                    <ol class="breadcrumb justify-content-center mb-0">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="breadcrumb-item active text-white">Services</li>
                    </ol>
                </div>
            </div>
            <div class="container-fluid bg-light service py-5" id='services'>
                <div class="container py-5">
                    <div class="mx-auto text-center mb-5" style={{ maxWidth: ' 900px' }}>
                        <h5 class="section-title px-3">Services</h5>
                        <h1 class="mb-0">Our Services</h1>
                    </div>
                    <div class="row g-4">
                        <div class="col-lg-6">
                            <div class="row g-4">
                                <div class="col-12">
                                    <div class="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 pe-0">
                                        <div class="service-icon p-4">
                                            <i class="fa fa-globe fa-4x text-primary"></i>
                                        </div>
                                        <div class="service-content text-start">
                                            <h5 class="mb-4">Tour Operators</h5>
                                            <p class="mb-0">Our top-notch management staff will help you at every turn to make your vacation successful. We carry out a wide variety of operations and duties both locally and internationally. As tour operators, we must provide you with the best service possible while keeping you updated on every aspect of your trip.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="service-content-inner d-flex align-items-center  bg-white border border-primary rounded p-4 pe-0">
                                        <div class="service-icon p-4">
                                            <i class="fa fa-car fa-4x text-primary"></i>
                                        </div>
                                        <div class="service-content text-start">
                                            <h5 class="mb-4">Car Coach Rental</h5>
                                            <p class="mb-0">Making your trip as comfortable as possible is the goal of a hired car or bus. If you need to go from one place to another, you always opt to rent a cosy car. We maintain a sizable fleet of vehicles of various makes and models. As a result of our cars' excellent maintenance and repairs, passengers may travel in luxury.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row g-4">
                                <div class="col-12">
                                    <div class="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 ps-0">
                                        <div class="service-icon p-4">
                                            <i class="fa fa-hotel fa-4x text-primary"></i>
                                        </div>
                                        <div class="service-content">
                                            <h5 class="mb-4">Hotel Booking</h5>
                                            <p class="mb-0">You don't have to deal with the hassle of choosing hotels, checking their amenities and determining the location. We can do it all at BHARAT TOURISM through our hotel booking services. Our team of experts help you to book hotels successfully for all kinds of travellers. Each hotel that we offer are known for their exemplary views, great hospitality, and budget stays Luxury.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 pe-0">
                                        <div class="service-icon p-4">
                                            <i class="fa fa-user fa-4x text-primary"></i>
                                        </div>
                                        <div class="service-content text-start">
                                            <h5 class="mb-4">Travel Guides</h5>
                                            <p class="mb-0">Our guides have many years of experience in leading historical tours. His deep knowledge and engaging storytelling bring the past to life, making every tour a memorable experience. Our guides are passionate about travel and dedicated to making your journey unforgettable.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services