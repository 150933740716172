import logo from './logo.svg';
import './App.css';
import HomePage from './Components/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import DetailsGoa from './Components/DetailsGoa';
import About from './Components/About';
import Services from './Components/Services';
import Packages from './Components/Packages';
import Contact from './Components/Contact';
import CanecellationPolicy from './Components/CanecellationPolicy';
import BookNow from './Components/BookNow';
import DetailsKulluManali from './Components/DetailsKulluManali';
import DetailsTadobaNational from './Components/DetailsTadobaNational';
import DetailsGujrat from './Components/DetailsGujrat';
import DetailsRajasthan from './Components/DetailsRajasthan';
import Gallery from './Components/Gallery';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='/goa-tour' element={<DetailsGoa />} />
          <Route path='/Kullu-Manali-tour-Details' element={<DetailsKulluManali />} />
          <Route path='/Tadoba-National-tour-Details' element={<DetailsTadobaNational />} />
          <Route path='/Gujarat-Tour-Details' element={<DetailsGujrat />} />
          <Route path='/Rajasthan-Tour-Details' element={<DetailsRajasthan />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/packages' element={<Packages />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/Canecellation-Policy' element={<CanecellationPolicy />} />
          <Route path='/Book-Now' element={<BookNow />} />
        </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
