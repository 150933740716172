import React from 'react'
import { Link } from 'react-router-dom'

const Contact = () => {
    return (
        <>
            <div class="container-fluid bg-contact">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h3 class="text-white display-3 mb-4">Contact Us</h3>
                    <ol class="breadcrumb justify-content-center mb-0">
                        <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="breadcrumb-item active text-white">Contact Us</li>
                    </ol>
                </div>
            </div>
            <div class="container-fluid contact bg-light py-5" id='contact'>
                <div class="container py-5">
                    <div class="mx-auto text-center mb-5" style={{ maxWidth: '900px' }}>
                        <h5 class="section-title px-3">Contact Us</h5>
                        <h1 class="mb-0">Contact For Any Query</h1>
                    </div>
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-4">
                            <div class="bg-white rounded p-4">
                                <div class="text-center mb-4">
                                    <i class="fa fa-map-marker-alt fa-3x text-primary"></i>
                                    <h4 class="text-primary">Address</h4>
                                    <p class="mb-0">New Delhi, India - 000000</p>
                                </div>
                                <div class="text-center mb-4">
                                    <i class="fa fa-phone-alt fa-3x text-primary mb-3"></i>
                                    <h4 class="text-primary">Mobile</h4>
                                    <p class="mb-0">+91 9832785010</p>
                                </div>

                                <div class="text-center">
                                    <i class="fa fa-envelope-open fa-3x text-primary mb-3"></i>
                                    <h4 class="text-primary">Email</h4>
                                    <p class="mb-0">bharattravelt@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <h2 class="mb-2">Send us a message</h2>
                            <form>
                                <div class="row g-3">
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control border-0" id="name" placeholder="Your Name" />
                                            <label for="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="email" class="form-control border-0" id="email" placeholder="Your Email" />
                                            <label for="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control border-0" id="subject" placeholder="Subject" />
                                            <label for="subject">Subject</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <textarea class="form-control border-0" placeholder="Leave a message here" id="message" style={{ height: '160px' }}></textarea>
                                            <label for="message">Message</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-12">
                            <div class="rounded">
                                <iframe class="rounded w-100"
                                    style={{ height: '450px' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.33750346623!2d-73.97968099999999!3d40.6974881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1694259649153!5m2!1sen!2sbd"
                                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact