import React from 'react'

const About = () => {
    return (
        <>
        <div class="container-fluid bg-goa">
            <div class="container text-center py-5" style={{maxWidth: '900px'}}>
                <h3 class="text-white display-3 mb-4">About Us</h3>
                <ol class="breadcrumb justify-content-center mb-0">
                    <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                    <li class="breadcrumb-item active text-white">About Us</li>
                </ol>    
            </div>
        </div>
            <div class="container-fluid about py-5" id='about'>
                <div class="container py-5">
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-5">
                            <div class="h-100" style={{ border: '50px solid', borderColor: 'transparent #13357B transparent #13357B' }}>
                                <img src="/img/about-2.jpg" class="img-fluid w-100 h-100" alt="" />
                            </div>
                        </div>
                        <div class="col-lg-7" >
                            <h5 class="section-about-title pe-3">About Us</h5>
                            <h1 class="mb-4">Welcome to <span class="text-primary">BHARAT TOURISM</span></h1>
                            <p class="mb-4">BHARAT TOURISM is tour and travel agency offering easy booking of domestic and international tour packages, premium hotels, flights, trains, cars for transportation, and more. We are the best travel agent that provides high-quality services and whom you trust for all your travel & tour requirements. We are dedicated to taking care of every aspect of a trip carefully. We go beyond all bounds to present the best of the destinations we offer.</p>
                            <p class="mb-4">At BHARAT TOURISM, we strive to create an impressive track record of service delivery. The advantage of BHARAT TOURISM over other agencies in the market is the competency of its professional team that works dedicatedly to achieve the company’s goal. Our team takes an ingenious approach to create a perfect travel plan for our guests.</p>
                            <div class="row gy-2 gx-4 mb-4">
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>First Class Flights</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>Handpicked Hotels</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>5 Star Accommodations</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>Latest Model Vehicles</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>150 Premium City Tours</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>24/7 Service</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About