import React from 'react'
import { Link } from 'react-router-dom'
const DetailsGujrat = () => {
    return (
        <>
            <div class="container-fluid bg-goa">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h3 class="text-white display-3 mb-4">Gujarat Tour</h3>
                    <ol class="breadcrumb justify-content-center mb-0">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="breadcrumb-item active text-white">Gujarat Tour</li>
                    </ol>
                </div>
            </div>
            <div class="container-fluid booking py-5">
                <div class="container py-5">
                    <div class="row g-5 align-items-center">
                        <div class="col-sm-6">
                            <h5 class="section-booking-title pe-3">Gujarat Tour Package</h5>
                            <h1 class="text-white mb-4">Gujarat Tour</h1>
                            <p class="text-white mb-4"><i className='fa fa-calendar'></i> &nbsp; 2 Nights 3 Days</p>
                            <p class="text-white mb-4"><b>Destination Covered : </b>Ahmedabad, Jamnagar, Dwarka, Somnath, Somnath, Gandhi Ashram, Bala Hanuman Temple</p>
                            <p class="text-white mb-4"><b>Tour Activities :</b> Heritage</p>
                            <p class="text-white mb-4"><b>Tour Themes : </b> Religious & Pilgrimage</p>
                            <p class="text-white mb-4">Gujarat, a vibrant state on the western coast of India, is renowned for its rich cultural heritage, diverse landscapes, and significant historical landmarks. Known for its industrious spirit and entrepreneurial dynamism, Gujarat is a pivotal economic hub with a thriving trade and business environment. The state boasts a stunning coastline along the Arabian Sea, with picturesque beaches and scenic coastal towns. Its cultural fabric is woven with colorful festivals, traditional dances, and unique cuisine, reflecting a blend of various communities and traditions.</p>
                            <div class="accordion" id="accordionPanelsStayOpenExample">
                                {/* <!-- Day 1 --> */}
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                            Day 1: Arrival at Ahmedabad
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                Arrive at Ahmedabad Airport/Railway Station. Check into the hotel. Then, enjoy local sightseeing in Ahmedabad, including Gandhi Ashram, Akshardham Temple, Akshar Hall, Vaishno Devi Temple, and Iskon Temple. In the evening, explore the local market for shopping. Overnight stay in Ahmedabad.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Day 2 --> */}
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                            Day 2: Ahmedabad - Jamnagar
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                After breakfast, proceed to Jamnagar. Check into the hotel and relax. Later, visit Bala Hanuman Temple, Lakhota Museum, Lakhota Lake, and other famous temples in Jamnagar. Evening free for local shopping.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Day 3 --> */}
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                            Day 3: Jamnagar - Dwarka
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                After breakfast, drive to Dwarka. Check into the hotel and relax. Today, visit Nageshwar Jyotirling, Gopi, Bet Dwarka, and Rukmani Temple. In the evening, attend the Dwarkadish Aarti. Overnight stay in Dwarka.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Day 4 --> */}
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                            Day 4: Dwarka - Somnath
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                Early morning, attend the Aarti at Dwarkadish Temple. Then leave for Somnath, with en-route visits to Porbandar (Birthplace of Mahatma Gandhi), Sudamapuri, and Bharat Mata Temple. Proceed to Somnath, visit the Somnath Temple, attend the evening Aarti, and enjoy the sound and light show at the temple premises.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Day 5 --> */}
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                            Day 5: Somnath - Gir Forest
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                After breakfast, leave for Gir Birding Lodge. Enjoy lunch at the lodge and then embark on an afternoon game drive by open Gypsy or similar vehicle in Gir National Park (additional cost). Evening free for nature walks and birding. Overnight stay at Gir.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Day 6 --> */}
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                                            Day 6: Somnath - Gir Forest
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                After breakfast, enjoy morning and evening safaris (additional cost) in Gir National Park to spot Asiatic Lions, Leopards, Spotted Deer, Peacocks, and various species of birds. Overnight stay at Gir.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Day 7 --> */}
                                <div class="accordion-item mt-2">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                                            Day 7: Drop at Ahmedabad Airport/Railway Station
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
                                        <div class="accordion-body">
                                            <p class="text-primary">
                                                In the morning, leave from Gir and drop off at Ahmedabad Airport/Railway Station.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Link to="/Book-Now" class="btn btn-light text-primary rounded-pill py-3 px-5 mt-2">Book Now</Link>
                                </div>
                                <div className="col pt-4 text-end">
                                    <Link to="/Canecellation-Policy" className='text-white'>Cancellation policy</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <img src="/img/gujrat.jpg" height="100%" width="100%" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsGujrat