import React from 'react'

const CanecellationPolicy = () => {
  return (
    <>
      <div class="container-fluid bg-goa">
        <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h3 class="text-white display-3 mb-4">Cancellation Policy</h3>
          <ol class="breadcrumb justify-content-center mb-0">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Pages</a></li>
            <li class="breadcrumb-item active text-white">Cancellation Policyr</li>
          </ol>
        </div>
      </div>
      <div class="container-fluid booking py-5">
        <div class="container py-5">
          <div class="row g-5 align-items-center">
            <div class="col-sm-12">
              <h5 class="section-booking-title pe-3">Cancellation Policy</h5>
              <h1 class="text-white mb-4">Cancellation Policy</h1><hr />
              <p class="text-white mb-4">•	Before 60 days of your travel date if you cancel your trip then 80% amount will be refundable of Total Package Cost. </p>
              <p class="text-white mb-4">•	Before 59 to 40 days of your travel date if you cancel your trip then 50% amount will be refundable of Total Package Cost. </p>
              <p class="text-white mb-4">•	Before 39 to 25 Days of your travel date if you cancel your trip 25% amount will be refundable of Total Package Cost. </p>
              <p class="text-white mb-4">•	Before 24 to 15 Days of your travel date if you cancel your trip then 15% amount will be refundable of Total Package Cost. </p>
              <p class="text-white mb-4">•	Less than 15 days of your travel date if you cancel your trip then 100 % charge will be applicable ( There is No Refund ) </p>
              <p class="text-white mb-4"><b>IN CASE : - If you travel any hill station, if there is any natural calamity or heavy snowfall use faced & if your trip is cancelled ,in that case , there is no refund policy will be applicable.</b></p>
             
              <h3 class="text-white mb-4 mt-5">COMPLAINTS AND CLAIMS </h3><hr />
              <p class="text-white mb-4">•	Whenever you feel unsatisfied with any service Ambika Tours has provided, please inform your local tour guide or your travel consultant immediately to avoid similar problems either before or during your trip. </p>
              <p class="text-white mb-4">•	If you want to complain or claim for compensation ,you must inform us by email,regular mail, or fax in writing within 15 days upon your tour is finished. Please attach relevant receipts and substantiating evidence to the letter of claim.</p>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CanecellationPolicy