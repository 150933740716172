import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            {/* <!-- Footer Start --> */}
            <div class="container-fluid footer py-5">
                <div class="container py-5">
                    <div class="row g-5">
                        <div class="col-md-6 col-lg-6 col-xl-3">
                            <div class="footer-item d-flex flex-column">
                                <a href="" className='pt-3'>
                                    {/* <h1 class="m-0"><i class="fa fa-plane me-3"></i>BHARAT TOURISM</h1> */}
                                    <img src="/img/logo(1).png" alt="Logo" width='200px' height='200px' />
                                </a>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-6 col-xl-3">
                            <div class="footer-item d-flex flex-column">
                                <h4 class="mb-4 text-white">Get In Touch</h4>
                                <a href=""><i class="fas fa-home me-2"></i> New Delhi, India - 000000</a>
                                <a href=""><i class="fas fa-envelope me-2"></i> bharattravelt@gmail.com</a>
                                <a href=""><i class="fas fa-phone me-2"></i> +91 9832785010</a>
                                <a href=""> <strong>Gst No : </strong>07GHTPS8550K1Z8</a>
                                <a href=""> <strong>MSME Govt. : </strong>Reg. No. UDYAM - DL- 07 - 0003491</a>
                               
                                
                            </div>
                        </div>


                        <div class="col-md-6 col-lg-6 col-xl-3">
                            <div class="footer-item d-flex flex-column">
                                <h4 class="mb-4 text-white">Company</h4>
                                <Link to="/"><i class="fas fa-angle-right me-2"></i> Home</Link>
                                <Link to="/about"><i class="fas fa-angle-right me-2"></i> About</Link>
                                <Link to="/services"><i class="fas fa-angle-right me-2"></i> Service</Link>
                                <Link to="/packages"><i class="fas fa-angle-right me-2"></i> Packages</Link>
                                <Link to="/contact-us"><i class="fas fa-angle-right me-2"></i> Contact</Link>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-3">
                            <div class="footer-item d-flex flex-column">
                                <h4 class="mb-4 text-white">Social Links</h4>
                                <div class="d-flex align-items-center">
                                    <i class="fas fa-share fa-2x text-white me-2"></i>
                                    <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                                    <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                                    <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer End --> */}

            {/* <!-- Copyright Start --> */}
            <div class="container-fluid copyright text-body py-4">
                <div class="container">
                    <div class="row g-4 align-items-center">
                        <div class="col-md-6 text-center text-md-end mb-md-0">
                            <i class="fas fa-copyright me-2"></i><a class="text-white" href="#">BHARAT TOURISM</a>, All right reserved.
                        </div>
                        {/* <div class="col-md-6 text-center text-md-start">
                            Designed By <a class="text-white" href="https://htmlcodex.com">HTML Codex</a> Distributed By <a href="https://themewagon.com">ThemeWagon</a>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* <!-- Copyright End --> */}
        </>
    )
}

export default Footer