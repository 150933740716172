import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const ExploreTour = () => {
    return (
        <div className="container-fluid ExploreTour py-5">
            <div className="container py-5">
                <div className="mx-auto text-center mb-5" style={{ maxWidth: '900px' }}>
                    <h5 className="section-title px-3">Explore Tour</h5>
                    <h1 className="mb-4">The World</h1>
                    <p className="mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum tempore nam, architecto doloremque velit explicabo? Voluptate sunt eveniet fuga eligendi! Expedita laudantium fugiat corrupti eum cum repellat a laborum quasi.
                    </p>
                </div>
                <div className="tab-class text-center">
                    <div id="InternationalTab-2" className="tab-pane fade show p-0">
                        <OwlCarousel
                            className="InternationalTour-carousel owl-carousel"
                            items={3}
                            loop
                            margin={10}
                            nav
                            navText={[
                                "<i class='fas fa-chevron-left'></i>",
                                "<i class='fas fa-chevron-right'></i>"
                            ]}
                            autoplay
                        >
                           <div className="international-item">
                                <img src="/img/taboda-5.jpg" className="img-fluid w-100 rounded" alt="France" />
                                <div className="international-content">
                                    <div className="international-info">
                                        <h5 className="text-white text-uppercase mb-2">Tadoba National Tour</h5>
                                        <a href="#" className="btn-hover text-white me-4">
                                            <i className="fas fa-map-marker-alt me-1"></i> 2 Night 3 Days
                                        </a>
                                        <a href="#" className="btn-hover text-white">
                                            <i className="fa fa-eye ms-2"></i> <span>Tour Themes : Wildlife</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="tour-offer bg-primary">20% Off</div>
                                <div className="international-plus-icon">
                                    <a href="#" className="my-auto">
                                        <i className="fas fa-link fa-2x text-white"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="international-item">
                                <img src="/img/goa-2.jpg" className="img-fluid w-100 rounded" alt="France" />
                                <div className="international-content">
                                    <div className="international-info">
                                        <h5 className="text-white text-uppercase mb-2">Goa Tour</h5>
                                        <a href="#" className="btn-hover text-white me-4">
                                            <i className="fas fa-map-marker-alt me-1"></i> 2 Nights 3 Days
                                        </a>
                                        <a href="#" className="btn-hover text-white">
                                            <i className="fa fa-eye ms-2"></i> <span>Tour Themes : Beaches and Islands</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="tour-offer bg-primary">20% Off</div>
                                <div className="international-plus-icon">
                                    <a href="#" className="my-auto">
                                        <i className="fas fa-link fa-2x text-white"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="international-item">
                                <img src="/img/Rajasthan.jpg" className="img-fluid w-100 rounded" alt="France" />
                                <div className="international-content">
                                    <div className="international-info">
                                        <h5 className="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                        <a href="#" className="btn-hover text-white me-4">
                                            <i className="fas fa-map-marker-alt me-1"></i> 3 Night 4 Day
                                        </a>
                                        <a href="#" className="btn-hover text-white">
                                            <i className="fa fa-eye ms-2"></i> <span>Tour Themes : Culture & Heritage</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="tour-offer bg-primary">20% Off</div>
                                <div className="international-plus-icon">
                                    <a href="#" className="my-auto">
                                        <i className="fas fa-link fa-2x text-white"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="international-item">
                                <img src="/img/gujrat.jpg" className="img-fluid w-100 rounded" alt="Australia" />
                                <div className="international-content">
                                    <div className="international-info">
                                        <h5 className="text-white text-uppercase mb-2">Gujarat</h5>
                                        <a href="#" className="btn-hover text-white me-4">
                                            <i className="fas fa-map-marker-alt me-1"></i> 6 Nights 7 Days
                                        </a>
                                        <a href="#" className="btn-hover text-white">
                                            <i className="fa fa-eye ms-2"></i> <span>Tour Themes : Religious & Pilgrimage</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="tour-offer bg-primary">30% Off</div>
                                <div className="international-plus-icon">
                                    <a href="#" className="my-auto">
                                        <i className="fas fa-link fa-2x text-white"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="international-item">
                                <img src="/img/kullu-manali-2.jpg" className="img-fluid w-100 rounded" alt="Australia" />
                                <div className="international-content">
                                    <div className="international-info">
                                        <h5 className="text-white text-uppercase mb-2">Kullu - Manali Tour</h5>
                                        <a href="#" className="btn-hover text-white me-4">
                                            <i className="fas fa-map-marker-alt me-1"></i> 4 Nights 5 Days
                                        </a>
                                        <a href="#" className="btn-hover text-white">
                                            <i className="fa fa-eye ms-2"></i> <span>Tour Themes : Hill Stations & Valleys</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="tour-offer bg-primary">30% Off</div>
                                <div className="international-plus-icon">
                                    <a href="#" className="my-auto">
                                        <i className="fas fa-link fa-2x text-white"></i>
                                    </a>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>

            {/* Internal CSS */}
            <style jsx>{`
                .owl-nav {
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    transform: translateY(-50%);
                    z-index: 10; /* Ensures buttons are above carousel content */
                }

                .owl-prev, .owl-next {
                    background: #000; /* Background color of buttons */
                    color: #fff; /* Text color of buttons */
                    border: none;
                    padding: 15px; /* Increase padding for larger buttons */
                    border-radius: 50%;
                    font-size: 24px; /* Larger font size for better visibility */
                    cursor: pointer;
                    transition: background 0.3s ease; /* Smooth background transition on hover */
                }

                .owl-prev:hover, .owl-next:hover {
                    background: #333; /* Darker background on hover */
                }

                .owl-prev {
                    left: 10px; /* Adjust the left position as needed */
                }

                .owl-next {
                    right: 10px; /* Adjust the right position as needed */
                }
            `}</style>
        </div>
    );
};

export default ExploreTour;
