import React from 'react'
import { Link } from 'react-router-dom'
const DetailsKulluManali = () => {
  return (
    <>
      <div class="container-fluid bg-goa">
        <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h3 class="text-white display-3 mb-4">Kullu - Manali Volvo Tour Package</h3>
          <ol class="breadcrumb justify-content-center mb-0">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Pages</a></li>
            <li class="breadcrumb-item active text-white">Kullu - Manali Tour</li>
          </ol>
        </div>
      </div>
      <div class="container-fluid booking py-5">
        <div class="container py-5">
          <div class="row g-5 align-items-center">
            <div class="col-sm-6">
              <h5 class="section-booking-title pe-3">Kullu - Manali Volvo Tour Package</h5>
              <h1 class="text-white mb-4">Kullu - Manali Tour Package</h1>
              <p class="text-white mb-4"><i className='fa fa-calendar'></i> &nbsp; 4 Nights 5 Days</p>
              <p class="text-white mb-4"><b>Destination Covered :  </b>Manali, Solang Valley</p>
              <p class="text-white mb-4"><b>Tour Activities : </b>Mountaineering</p>
              <p class="text-white mb-4"><b>Tour Themes : </b>Hill Stations & Valleys</p>
              <p class="text-white mb-4">Kullu and Manali, nestled in the picturesque Kullu Valley of Himachal Pradesh, India, offer a blend of natural beauty and adventure. Kullu, located at a lower altitude, is renowned for its lush meadows, apple orchards, and serene landscapes, with attractions like the Great Himalayan National Park and the spiritual Manikaran hot springs. Just 40 km north lies Manali, a higher-altitude hill station famous for its vibrant cultural scene and adventure sports. Visitors to Manali can enjoy the ancient Hadimba Temple, thrilling activities in Solang Valley, and stunning vistas from Rohtang Pass. Together, Kullu and Manali provide a diverse range of experiences, from tranquil retreats to exhilarating outdoor adventures.</p>
              <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      Day 1 : Delhi – Manali
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                      <p className='text-primary'>Arrive in Delhi By Air or Train . After reaching in Delhi go to Minto Road, Gandhi Market, ( On your own ) at 5.00 PM . at sharp 5.30 P.M Depart from Delhi to Manali . Travel by Overnight Volvo Bus from Delhi to Manali (12 – 14 Hrs ) Manali is also famous for Adventure sports like skilling, hiking, mountaineering, paragliding Rafting, trekking, kayaking, and mountain biking.
                        In short, Manali- Is the “Valley of the Gods” and an ideal place for the ones in search of both adventure and comfort, reach Manali next morning at 6 -8 AM</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mt-2">
                  <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      Day 2 : Manali Arrival ( Local Sightseeing )
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                    <div class="accordion-body">
                      <p className='text-primary'>On arrival at Manali ,our representative assists to transfer you to the pre-booked hotel. Check in at the hotel,freshen up and enjoy leisure time at the comfort of your room. After some time enjoy the city tour visiting to some of the prominent spots and attractions including Hadimba Devi Temple also called Dhungri Temple, Tibetan Monastery and Vashisth village. After enjoying exciting tour of Manali city return back by afternoon and enjoy at your free will ( reserved for leisure ) Dinner and Overnight stay will be the Hotel in Manali.
                        <br />
                        <b>Highlights :</b>
                        <ol>
                          <li>Hadimba Devi Temple</li>
                          <li>Vashist Village & Temple</li>
                          <li>Van Vihar</li>
                          <li>Tibetan Monastery</li>
                          <li>Club House</li>
                          <li>Mall Road</li>
                        </ol>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mt-2">
                  <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      Day 3 : Manali ( Full day Trip to solang Valley )
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <p className='text-primary'>
                        Enjoy the morning breakfast at the hotel and later get ready for an excursion to visit Solang Valley is outstandingly beautiful and is an spot to view the majestic snow Covered Himalayas, serene natural surrondings, pristine lake and fairy white snow round the year. Return back to the hotel on time for dinner and overnight stay.
                        <br /><b>Highlights :</b>
                        <ol>
                          <li>Solang Valley</li>
                          <li>Paragliding</li>
                          <li>Skating</li>
                          <li>Trekking</li>
                          <li>Photography</li>
                        </ol>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mt-2">
                  <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      Day 4 : Manali - Delhi
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                    <div class="accordion-body">
                      <p className='text-primary'>
                        Morning after breakfast check out from the hotel and later get ready for an excursion to visit Naggar the ancient capital of kullu & visit the famous Art Gallery, Naggar Castle, Fish Farm, Waterfall etc. Return back to kullu town enroute visiting. The famous kullu shawl industry . Evening catch the Volvo from kullu Overnight Journey to Delhi .
                        <br /><b>Highlights :</b>
                        <ol>
                          <li>Kullu Valley</li>
                          <li>Beas River Rafting</li>
                          <li>Naggar Castle</li>
                          <li>Art Gallery</li>
                          <li>FishFarm</li>
                          <li>Raison Waterfalls</li>
                          <li>Buddhist Temple</li>
                        </ol>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mt-2">
                  <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      Day 5 : Arrival at Delhi – Tour Ends
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                    <div class="accordion-body">
                      <p className='text-primary'>
                        Arrival at Delhi , the glorious tour to the heavenly Manali comes to an end. In this way your visit to one of the paradise on earth located amidst the lofty Himalayas ends Offering your glorious memories to cherish for lifetime.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Link to="/Book-Now" class="btn btn-light text-primary rounded-pill py-3 px-5 mt-2">Book Now</Link>
                </div>
                <div className="col pt-4 text-end">
                  <Link to="/Canecellation-Policy" className='text-white'>Cancellation policy</Link>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              {/* <img src="/img/kullu-manali-2.jpg" height="100%" width="100%" alt="" /> */}
              <img src="/img/kullu-manali.png" height="100%" width="100%" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailsKulluManali