import React from 'react'
import { Link } from 'react-router-dom'
import ExploreTour from './ExploreTour'

const Packages = () => {
    return (
        <>
            <div class="container-fluid bg-breadcrumb">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h3 class="text-white display-3 mb-4">Packages</h3>
                    <ol class="breadcrumb justify-content-center mb-0">
                        <li class="breadcrumb-item text-primary">Home</li>
                        <li class="breadcrumb-item text-primary">Pages</li>
                        <li class="breadcrumb-item active text-white">Packages</li>
                    </ol>
                </div>
            </div>
            <div class="container-fluid packages py-5 pb-5" id='packages'>
                <div class="container py-5">
                    <div class="mx-auto text-center mb-5" style={{ maxWidth: ' 900px' }}>
                        <h5 class="section-title px-3">Packages</h5>
                        <h1 class="mb-0">Awesome Packages</h1>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-md-4 mt-3">
                            <div className="packages-item">
                                <div className="packages-img">
                                    <img src="/img/kullu-manali.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                                    <div className="packages-info d-flex border border-start-0 border-end-0 position-absolute" style={{ width: '100%', bottom: '0', left: '0', zIndex: '5' }}>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-map-marker-alt me-2"></i>Kullu - Manali Volvo Tour Package</small>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt me-2"></i> 4 Nights 5 Days</small>
                                        {/* <small className="flex-fill text-center py-2"><i className="fa fa-user me-2"></i>2 Person</small> */}
                                    </div>
                                    <div className="packages-price py-2 px-4" style={{ fontSize: 'smaller' }}> Price On Request</div>
                                </div>
                                <div className="packages-content bg-light">
                                    <div className="p-4 pb-0">
                                        <h5 className="mb-0">Kullu - Manali Volvo Tour Package</h5>
                                        {/* <small className="text-uppercase">Hotel Deals</small> */}
                                        <div className="mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <p className='text-dark'><b>Destination Covered : </b>  Manali, Solang Valley</p>
                                        <p className='text-dark'><b>Tour Activities : </b> Mountaineering </p>
                                        <p className='text-dark'><b>Tour Themes : </b> Hill Stations & Valleys </p>
                                    </div>
                                    <div className="row bg-primary rounded-bottom mx-0">
                                        <div className="col-6 text-end px-0">
                                            <Link to="/Kullu-Manali-tour-Details" class="btn-hover btn text-white py-2 px-4">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 col-md-4 mt-3">
                            <div class="packages-item">
                                <div class="packages-img">
                                    <img src="/img/taboda.jpg" class="img-fluid w-100 rounded-top" alt="Image" />
                                    <div class="packages-info d-flex border border-start-0 border-end-0 position-absolute" style={{ width: '100%', bottom: '0', left: 0, zIndex: '5', }}>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-map-marker-alt me-2"></i>Tadoba National Park Tour</small>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-calendar-alt me-2"></i>2 Night 3 Days</small>
                                    </div>
                                    <div className="packages-price py-2 px-4" style={{ fontSize: 'smaller' }}> Price On Request</div>
                                </div>
                                <div class="packages-content bg-light">
                                    <div class="p-4 pb-0">
                                        <h5 class="mb-0">Tadoba National  Tour</h5>
                                        <div class="mb-3">
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                        </div>
                                        <p className="text-dark"><b> Destination Covered :</b> Nagpur </p>
                                        <p className="text-dark"><b> Tour Activities :</b>  Jungle Safari, Jeep Safari</p>
                                        <p className="text-dark"><b> Tour Themes :</b>  Wildlife</p>
                                    </div>
                                    <div class="row bg-primary rounded-bottom mx-0">
                                        <div class="col-6 text-end px-0">
                                            <Link to="/Tadoba-National-tour-Details" class="btn-hover btn text-white py-2 px-4">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 col-md-4 mt-3">
                            <div class="packages-item">
                                <div class="packages-img">
                                    <img src="/img/goa.jpg" class="img-fluid w-100 rounded-top" alt="Image" />
                                    <div class="packages-info d-flex border border-start-0 border-end-0 position-absolute" style={{ width: '100%', bottom: '0', left: 0, zIndex: '5', }}>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-map-marker-alt me-2"></i>Goa Tour Package</small>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-calendar-alt me-2"></i>2 Nights 3 Days</small>
                                    </div>
                                    <div className="packages-price py-2 px-4" style={{ fontSize: 'smaller' }}> Price On Request</div>
                                </div>
                                <div class="packages-content bg-light">
                                    <div class="p-4 pb-0">
                                        <h5 class="mb-0">Goa Tour Package</h5>
                                        <div class="mb-3">
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                        </div>
                                        <p className="text-dark"><b> Destination Covered :</b>  North Goa </p>
                                        <p className="text-dark"><b> Tour Activities :</b> Beaches Sightseeing and water Activities</p>
                                        <p className="text-dark"><b> Tour Themes :</b>  Beaches and Islands</p>
                                    </div>
                                    <div class="row bg-primary rounded-bottom mx-0">
                                        <div class="col-6 text-end px-0">
                                            <Link to="/goa-tour" class="btn-hover btn text-white py-2 px-4">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 mt-3">
                            <div class="packages-item">
                                <div class="packages-img">
                                    <img src="/img/gujrat.jpg" class="img-fluid w-100 h-500 rounded-top" alt="Image" height={'700px'} />
                                    <div class="packages-info d-flex border border-start-0 border-end-0 position-absolute" style={{ width: '100%', bottom: '0', left: 0, zIndex: '5', }}>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-map-marker-alt me-2"></i>Gujarat Tour</small>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-calendar-alt me-2"></i>6 Nights 7 Days</small>
                                    </div>
                                    <div className="packages-price py-2 px-4" style={{ fontSize: 'smaller' }}> Price On Request</div>
                                </div>
                                <div class="packages-content bg-light">
                                    <div class="p-4 pb-0">
                                        <h5 class="mb-0">Gujarat Tour Package</h5>
                                        <div class="mb-3">
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                        </div>
                                        <p className="text-dark"><b> Destination Covered :</b> Ahmedabad, Jamnagar, Dwarka, Somnat ... </p>
                                        <p className="text-dark"><b> Tour Activities :</b>  Heritage</p>
                                        <p className="text-dark"><b> Tour Themes :</b>  Religious & Pilgrimage</p>
                                    </div>
                                    <div class="row bg-primary rounded-bottom mx-0">
                                        {/* <div class="col-6 text-start px-0">
                                            <a href="#" class="btn-hover btn text-white py-2 px-4">Read More</a>
                                        </div> */}
                                        <div class="col-6 text-end px-0">
                                            <Link to="/Gujarat-Tour-Details" class="btn-hover btn text-white py-2 px-4">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 mt-3">
                            <div className="packages-item">
                                <div className="packages-img">
                                    <img src="/img/Rajasthan.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                                    <div className="packages-info d-flex border border-start-0 border-end-0 position-absolute" style={{ width: '100%', bottom: '0', left: '0', zIndex: '5' }}>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-map-marker-alt me-2"></i>Rajasthan Tour Package</small>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt me-2"></i>3 Night 4 Day </small>
                                        {/* <small className="flex-fill text-center py-2"><i className="fa fa-user me-2"></i>2 Person</small> */}
                                    </div>
                                    <div className="packages-price py-2 px-4" style={{ fontSize: 'smaller' }}> Price On Request</div>
                                </div>
                                <div className="packages-content bg-light">
                                    <div className="p-4 pb-0">
                                        <h5 className="mb-0">Rajasthan Tour Package</h5>
                                        {/* <small className="text-uppercase">Hotel Deals</small> */}
                                        <div className="mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <p className='text-dark'><b>Destination Covered : </b>  Jaipur, Udaipur, Jaigarh Fort, Hawa Mahal, Lake Pichola ...</p>
                                        <p className='text-dark'><b>Tour Activities : </b> Forts and Palaces, Museums </p>
                                        <p className='text-dark'><b>Tour Themes : </b> Culture & Heritage </p>
                                        {/* <p className='text-dark' className="mb-4">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nesciunt nemo quia quae illum aperiam fugiat voluptatem repellat</p> */}
                                    </div>
                                    <div className="row bg-primary rounded-bottom mx-0">
                                        {/* <div className="col-6 text-start px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Read More</a>
                                        </div> */}
                                        <div className="col-6 text-end px-0">
                                            <Link to="/Rajasthan-Tour-Details" class="btn-hover btn text-white py-2 px-4">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <div className="container-fluid pb-5">
                <ExploreTour />
            </div>
        </>
    )
}

export default Packages