import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ExploreTour from './ExploreTour';
import { Link } from 'react-router-dom';
const HomePage = () => {

    return (
        <>
            {/* <!-- Navbar & Hero Start --> */}
            <div class="carousel-header mb-5">
                <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-bs-target="#carouselId" data-bs-slide-to="0" class="active"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="3"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="4"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="5"></li>
                    </ol>
                    <div class="carousel-inner" role="banner">
                        <div class="carousel-item active">
                            <img src="/img/Places/Jodhpur.jpg" class="img-fluid" alt="Jodhpur view" />
                            <div class="carousel-caption">
                                <div class="p-3 carousel-caption-container">
                                    <h4 class="text-white text-uppercase fw-bold mb-4" style={{ letterSpacing: '3px' }}>Welcome to BHARAT TOURISM </h4>
                                    <p class="mb-5 fs-5">Explore A New Treat</p>
                                    <h1 class="display-2 text-capitalize text-white mb-4">Jodhpur Tour</h1>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <a class="btn-hover-bg btn btn-primary rounded-pill text-white py-3 px-5" href="#packages">Discover Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="/img/Places/Jaipur.jpg" class="img-fluid" alt="Image" />
                            <div class="carousel-caption">
                                <div class="p-3" style={{ maxWidth: ' 900px' }}>
                                    <h4 class="text-white text-uppercase fw-bold mb-4" style={{ letterSpacing: ' 3px' }}>Welcome to BHARAT TOURISM </h4>
                                    <h1 class="display-2 text-capitalize text-white mb-4">Jaipur Tour</h1>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <a class="btn-hover-bg btn btn-primary rounded-pill text-white py-3 px-5" href="#packages">Discover Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="/img/Places/Manali.jpg" class="img-fluid" alt="Image" />
                            <div class="carousel-caption">
                                <div class="p-3" style={{ maxWidth: ' 900px' }}>
                                    <h4 class="text-white text-uppercase fw-bold mb-4" style={{ letterSpacing: ' 3px' }}>Welcome to BHARAT TOURISM </h4>
                                    <h1 class="display-2 text-capitalize text-white mb-4">Manali Tour</h1>
                                    
                                    <div class="d-flex align-items-center justify-content-center">
                                        <a class="btn-hover-bg btn btn-primary rounded-pill text-white py-3 px-5" href="#packages">Discover Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="/img/Places/Shimla.jpg" class="img-fluid" alt="Image" />
                            <div class="carousel-caption">
                                <div class="p-3" style={{ maxWidth: ' 900px' }}>
                                    <h4 class="text-white text-uppercase fw-bold mb-4" style={{ letterSpacing: ' 3px' }}>Welcome to BHARAT TOURISM </h4>
                                    <h1 class="display-2 text-capitalize text-white mb-4">Shimla Tour</h1>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <a class="btn-hover-bg btn btn-primary rounded-pill text-white py-3 px-5" href="#packages">Discover Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="/img/Places/dalhousie.jpg" class="img-fluid" alt="Image" />
                            <div class="carousel-caption">
                                <div class="p-3" style={{ maxWidth: ' 900px' }}>
                                    <h4 class="text-white text-uppercase fw-bold mb-4" style={{ letterSpacing: ' 3px' }}>Welcome to BHARAT TOURISM </h4>
                                    <h1 class="display-2 text-capitalize text-white mb-4">Dalhousie Tour</h1>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <a class="btn-hover-bg btn btn-primary rounded-pill text-white py-3 px-5" href="#packages">Discover Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon btn bg-primary" aria-hidden="false"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                        <span class="carousel-control-next-icon btn bg-primary" aria-hidden="false"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div> 
            {/* <div>
                <video width="100%"  autoPlay loop controls={false}>
                    <source src="/Video/welcome.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div> */}
            {/* <!-- Navbar & Hero End --> */}
            <div class="container-fluid about py-5" id='about'>
                <div class="container py-5">
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-5">
                            <div class="h-100" style={{ border: '50px solid', borderColor: 'transparent #13357B transparent #13357B' }}>
                                <img src="/img/about-2.jpg" class="img-fluid w-100 h-100" alt="" />
                            </div>
                        </div>
                        <div class="col-lg-7" >
                            <h5 class="section-about-title pe-3">About Us</h5>
                            <h1 class="mb-4">Welcome to <span class="text-primary">BHARAT TOURISM</span></h1>
                            <p class="mb-4">BHARAT TOURISM is a Nagpur (Maharashtra)-based tour and travel agency offering easy booking of domestic and international tour packages, premium hotels, flights, trains, cars for transportation, and more. We are the best travel agent that provides high-quality services and whom you trust for all your travel & tour requirements. We are dedicated to taking care of every aspect of a trip carefully. We go beyond all bounds to present the best of the destinations we offer.</p>
                            <p class="mb-4">At BHARAT TOURISM, we strive to create an impressive track record of service delivery. The advantage of BHARAT TOURISM over other agencies in the market is the competency of its professional team that works dedicatedly to achieve the company’s goal. Our team takes an ingenious approach to create a perfect travel plan for our guests.</p>
                            <div class="row gy-2 gx-4 mb-4">
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>First Class Flights</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>Handpicked Hotels</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>5 Star Accommodations</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>Latest Model Vehicles</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>150 Premium City Tours</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>24/7 Service</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid bg-light service py-5" id='services'>
                <div class="container py-5">
                    <div class="mx-auto text-center mb-5" style={{ maxWidth: ' 900px' }}>
                        <h5 class="section-title px-3">Services</h5>
                        <h1 class="mb-0">Our Services</h1>
                    </div>
                    <div class="row g-4">
                        <div class="col-lg-6">
                            <div class="row g-4">
                                <div class="col-12">
                                    <div class="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 pe-0">
                                        <div class="service-icon p-4">
                                            <i class="fa fa-globe fa-4x text-primary"></i>
                                        </div>
                                        <div class="service-content text-start">
                                            <h5 class="mb-4">Tour Operators</h5>
                                            <p class="mb-0">Our top-notch management staff will help you at every turn to make your vacation successful. We carry out a wide variety of operations and duties both locally and internationally. As tour operators, we must provide you with the best service possible while keeping you updated on every aspect of your trip.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="service-content-inner d-flex align-items-center  bg-white border border-primary rounded p-4 pe-0">
                                        <div class="service-icon p-4">
                                            <i class="fa fa-car fa-4x text-primary"></i>
                                        </div>
                                        <div class="service-content text-start">
                                            <h5 class="mb-4">Car Coach Rental</h5>
                                            <p class="mb-0">Making your trip as comfortable as possible is the goal of a hired car or bus. If you need to go from one place to another, you always opt to rent a cosy car. We maintain a sizable fleet of vehicles of various makes and models. As a result of our cars' excellent maintenance and repairs, passengers may travel in luxury.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row g-4">
                                <div class="col-12">
                                    <div class="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 ps-0">
                                        <div class="service-icon p-4">
                                            <i class="fa fa-hotel fa-4x text-primary"></i>
                                        </div>
                                        <div class="service-content">
                                            <h5 class="mb-4">Hotel Booking</h5>
                                            <p class="mb-0">You don't have to deal with the hassle of choosing hotels, checking their amenities and determining the location. We can do it all at BHARAT TOURISM through our hotel booking services. Our team of experts help you to book hotels successfully for all kinds of travellers. Each hotel that we offer are known for their exemplary views, great hospitality, and budget stays Luxury.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 pe-0">
                                        <div class="service-icon p-4">
                                            <i class="fa fa-user fa-4x text-primary"></i>
                                        </div>
                                        <div class="service-content text-start">
                                            <h5 class="mb-4">Travel Guides</h5>
                                            <p class="mb-0">Our guides have many years of experience in leading historical tours. His deep knowledge and engaging storytelling bring the past to life, making every tour a memorable experience. Our guides are passionate about travel and dedicated to making your journey unforgettable.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="container-fluid destination py-5" id='destinations'>
                <div class="container py-5">
                    <div class="mx-auto text-center mb-5" style={{ maxWidth: ' 900px' }}>
                        <h5 class="section-title px-3">Destination</h5>
                        <h1 class="mb-0">Popular Destination</h1>
                    </div>
                    <div class="tab-class text-center">
                        <ul class="nav nav-pills d-inline-flex justify-content-center mb-5">
                            <li class="nav-item">
                                <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill active" data-bs-toggle="pill" href="#tab-1">
                                    <span class="text-dark" style={{ width: '150px' }}>All</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="d-flex py-2 mx-3 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-2">
                                    <span class="text-dark" style={{ width: '150px' }}>USA</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-3">
                                    <span class="text-dark" style={{ width: '150px' }}>Canada</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-4">
                                    <span class="text-dark" style={{ width: '150px' }}>Europe</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-5">
                                    <span class="text-dark" style={{ width: '150px' }}>China</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-6">
                                    <span class="text-dark" style={{ width: '150px' }}>Singapore</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div id="tab-1" class="tab-pane fade show p-0 active">
                                <div class="row g-4">
                                    <div class="col-xl-8">
                                        <div class="row g-4">
                                            <div class="col-lg-6">
                                                <div class="destination-img">
                                                    <img class="img-fluid rounded w-100" src="/img/destination-1.jpg" alt="" />
                                                    <div class="destination-overlay p-4">
                                                        <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                        <h4 class="text-white mb-2 mt-3">New York City</h4>
                                                        <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                                    </div>
                                                    <div class="search-icon">
                                                        <a href="/img/destination-1.jpg" data-lightbox="destination-1"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="destination-img">
                                                    <img class="img-fluid rounded w-100" src="/img/destination-2.jpg" alt="" />
                                                    <div class="destination-overlay p-4">
                                                        <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                        <h4 class="text-white mb-2 mt-3">Las vegas</h4>
                                                        <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                                    </div>
                                                    <div class="search-icon">
                                                        <a href="/img/destination-2.jpg" data-lightbox="destination-2"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="destination-img">
                                                    <img class="img-fluid rounded w-100" src="/img/destination-7.jpg" alt="" />
                                                    <div class="destination-overlay p-4">
                                                        <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                        <h4 class="text-white mb-2 mt-3">Los angelas</h4>
                                                        <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                                    </div>
                                                    <div class="search-icon">
                                                        <a href="/img/destination-7.jpg" data-lightbox="destination-7"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="destination-img">
                                                    <img class="img-fluid rounded w-100" src="/img/destination-8.jpg" alt="" />
                                                    <div class="destination-overlay p-4">
                                                        <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                        <h4 class="text-white mb-2 mt-3">Los angelas</h4>
                                                        <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                                    </div>
                                                    <div class="search-icon">
                                                        <a href="/img/destination-8.jpg" data-lightbox="destination-8"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                        <div class="destination-img h-100">
                                            <img class="img-fluid rounded w-100 h-100" src="/img/destination-9.jpg" style={{ objectFit: 'cover', minHeight: '300px' }} alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-9.jpg" data-lightbox="destination-4"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-4.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">Los angelas</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-4.jpg" data-lightbox="destination-4"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-5.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">Los angelas</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-5.jpg" data-lightbox="destination-5"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-6.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">Los angelas</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-6.jpg" data-lightbox="destination-6"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-2" class="tab-pane fade show p-0">
                                <div class="row g-4">
                                    <div class="col-lg-6">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-5.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-5.jpg" data-lightbox="destination-5"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-6.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-6.jpg" data-lightbox="destination-6"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-3" class="tab-pane fade show p-0">
                                <div class="row g-4">
                                    <div class="col-lg-6">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-5.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-5.jpg" data-lightbox="destination-5"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-6.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-6.jpg" data-lightbox="destination-6"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-4" class="tab-pane fade show p-0">
                                <div class="row g-4">
                                    <div class="col-lg-6">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-5.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-5.jpg" data-lightbox="destination-5"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-6.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-6.jpg" data-lightbox="destination-6"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-5" class="tab-pane fade show p-0">
                                <div class="row g-4">
                                    <div class="col-lg-6">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-5.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-5.jpg" data-lightbox="destination-5"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-6.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-6.jpg" data-lightbox="destination-6"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-6" class="tab-pane fade show p-0">
                                <div class="row g-4">
                                    <div class="col-lg-6">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-5.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-5.jpg" data-lightbox="destination-5"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="destination-img">
                                            <img class="img-fluid rounded w-100" src="/img/destination-6.jpg" alt="" />
                                            <div class="destination-overlay p-4">
                                                <a href="#" class="btn btn-primary text-white rounded-pill border py-2 px-3">20 Photos</a>
                                                <h4 class="text-white mb-2 mt-3">San francisco</h4>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                            <div class="search-icon">
                                                <a href="/img/destination-6.jpg" data-lightbox="destination-6"><i class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-primary"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <ExploreTour />
            <div class="container-fluid packages py-5" id='packages'>
                <div class="container py-5">
                    <div class="mx-auto text-center mb-5" style={{ maxWidth: ' 900px' }}>
                        <h5 class="section-title px-3">Packages</h5>
                        <h1 class="mb-0">Awesome Packages</h1>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-md-4 mt-3">
                            <div className="packages-item">
                                <div className="packages-img">
                                    <img src="/img/kullu-manali.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                                    <div className="packages-info d-flex border border-start-0 border-end-0 position-absolute" style={{ width: '100%', bottom: '0', left: '0', zIndex: '5' }}>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-map-marker-alt me-2"></i>Kullu - Manali Volvo Tour Package</small>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt me-2"></i> 4 Nights 5 Days</small>
                                        {/* <small className="flex-fill text-center py-2"><i className="fa fa-user me-2"></i>2 Person</small> */}
                                    </div>
                                    <div className="packages-price py-2 px-4" style={{ fontSize: 'smaller' }}> Price On Request</div>
                                </div>
                                <div className="packages-content bg-light">
                                    <div className="p-4 pb-0">
                                        <h5 className="mb-0">Kullu - Manali Volvo Tour Package</h5>
                                        {/* <small className="text-uppercase">Hotel Deals</small> */}
                                        <div className="mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <p className='text-dark'><b>Destination Covered : </b>  Manali, Solang Valley</p>
                                        <p className='text-dark'><b>Tour Activities : </b> Mountaineering </p>
                                        <p className='text-dark'><b>Tour Themes : </b> Hill Stations & Valleys </p>
                                    </div>
                                    <div className="row bg-primary rounded-bottom mx-0">
                                        <div className="col-6 text-end px-0">
                                            <Link to="/Kullu-Manali-tour-Details" class="btn-hover btn text-white py-2 px-4">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 col-md-4 mt-3">
                            <div class="packages-item">
                                <div class="packages-img">
                                    <img src="/img/taboda.jpg" class="img-fluid w-100 rounded-top" alt="Image" />
                                    <div class="packages-info d-flex border border-start-0 border-end-0 position-absolute" style={{ width: '100%', bottom: '0', left: 0, zIndex: '5', }}>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-map-marker-alt me-2"></i>Tadoba National Park Tour</small>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-calendar-alt me-2"></i>2 Night 3 Days</small>
                                    </div>
                                    <div className="packages-price py-2 px-4" style={{ fontSize: 'smaller' }}> Price On Request</div>
                                </div>
                                <div class="packages-content bg-light">
                                    <div class="p-4 pb-0">
                                        <h5 class="mb-0">Tadoba National  Tour</h5>
                                        <div class="mb-3">
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                        </div>
                                        <p className="text-dark"><b> Destination Covered :</b> Nagpur </p>
                                        <p className="text-dark"><b> Tour Activities :</b>  Jungle Safari, Jeep Safari</p>
                                        <p className="text-dark"><b> Tour Themes :</b>  Wildlife</p>
                                    </div>
                                    <div class="row bg-primary rounded-bottom mx-0">
                                        <div class="col-6 text-end px-0">
                                            <Link to="/Tadoba-National-tour-Details" class="btn-hover btn text-white py-2 px-4">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 col-md-4 mt-3">
                            <div class="packages-item">
                                <div class="packages-img">
                                    <img src="/img/goa.jpg" class="img-fluid w-100 rounded-top" alt="Image" />
                                    <div class="packages-info d-flex border border-start-0 border-end-0 position-absolute" style={{ width: '100%', bottom: '0', left: 0, zIndex: '5', }}>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-map-marker-alt me-2"></i>Goa Tour Package</small>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-calendar-alt me-2"></i>2 Nights 3 Days</small>
                                    </div>
                                    <div className="packages-price py-2 px-4" style={{ fontSize: 'smaller' }}> Price On Request</div>
                                </div>
                                <div class="packages-content bg-light">
                                    <div class="p-4 pb-0">
                                        <h5 class="mb-0">Goa Tour Package</h5>
                                        <div class="mb-3">
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                        </div>
                                        <p className="text-dark"><b> Destination Covered :</b>  North Goa </p>
                                        <p className="text-dark"><b> Tour Activities :</b> Beaches Sightseeing and water Activities</p>
                                        <p className="text-dark"><b> Tour Themes :</b>  Beaches and Islands</p>
                                    </div>
                                    <div class="row bg-primary rounded-bottom mx-0">
                                        <div class="col-6 text-end px-0">
                                            <Link to="/goa-tour" class="btn-hover btn text-white py-2 px-4">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 mt-3">
                            <div class="packages-item">
                                <div class="packages-img">
                                    <img src="/img/gujrat.jpg" class="img-fluid w-100 h-500 rounded-top" alt="Image" height={'700px'} />
                                    <div class="packages-info d-flex border border-start-0 border-end-0 position-absolute" style={{ width: '100%', bottom: '0', left: 0, zIndex: '5', }}>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-map-marker-alt me-2"></i>Gujarat Tour</small>
                                        <small class="flex-fill text-center border-end py-2"><i class="fa fa-calendar-alt me-2"></i>6 Nights 7 Days</small>
                                    </div>
                                    <div className="packages-price py-2 px-4" style={{ fontSize: 'smaller' }}> Price On Request</div>
                                </div>
                                <div class="packages-content bg-light">
                                    <div class="p-4 pb-0">
                                        <h5 class="mb-0">Gujarat Tour Package</h5>
                                        <div class="mb-3">
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                            <small class="fa fa-star text-primary"></small>
                                        </div>
                                        <p className="text-dark"><b> Destination Covered :</b> Ahmedabad, Jamnagar, Dwarka, Somnat ... </p>
                                        <p className="text-dark"><b> Tour Activities :</b>  Heritage</p>
                                        <p className="text-dark"><b> Tour Themes :</b>  Religious & Pilgrimage</p>
                                    </div>
                                    <div class="row bg-primary rounded-bottom mx-0">
                                        {/* <div class="col-6 text-start px-0">
                                            <a href="#" class="btn-hover btn text-white py-2 px-4">Read More</a>
                                        </div> */}
                                        <div class="col-6 text-end px-0">
                                            <Link to="/Gujarat-Tour-Details" class="btn-hover btn text-white py-2 px-4">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 mt-3">
                            <div className="packages-item">
                                <div className="packages-img">
                                    <img src="/img/Rajasthan.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                                    <div className="packages-info d-flex border border-start-0 border-end-0 position-absolute" style={{ width: '100%', bottom: '0', left: '0', zIndex: '5' }}>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-map-marker-alt me-2"></i>Rajasthan Tour Package</small>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt me-2"></i>3 Night 4 Day </small>
                                        {/* <small className="flex-fill text-center py-2"><i className="fa fa-user me-2"></i>2 Person</small> */}
                                    </div>
                                    <div className="packages-price py-2 px-4" style={{ fontSize: 'smaller' }}> Price On Request</div>
                                </div>
                                <div className="packages-content bg-light">
                                    <div className="p-4 pb-0">
                                        <h5 className="mb-0">Rajasthan Tour Package</h5>
                                        {/* <small className="text-uppercase">Hotel Deals</small> */}
                                        <div className="mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <p className='text-dark'><b>Destination Covered : </b>  Jaipur, Udaipur, Jaigarh Fort, Hawa Mahal, Lake Pichola ...</p>
                                        <p className='text-dark'><b>Tour Activities : </b> Forts and Palaces, Museums </p>
                                        <p className='text-dark'><b>Tour Themes : </b> Culture & Heritage </p>
                                        {/* <p className='text-dark' className="mb-4">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nesciunt nemo quia quae illum aperiam fugiat voluptatem repellat</p> */}
                                    </div>
                                    <div className="row bg-primary rounded-bottom mx-0">
                                        {/* <div className="col-6 text-start px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Read More</a>
                                        </div> */}
                                        <div className="col-6 text-end px-0">
                                            <Link to="/Rajasthan-Tour-Details" class="btn-hover btn text-white py-2 px-4">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <div class="container-fluid gallery py-5 my-5" id='gallery'>
                <div class="mx-auto text-center mb-5" style={{ maxWidth: ' 900px' }}>
                    <h5 class="section-title px-3">Our Gallery</h5>
                    <h1 class="mb-4">Tourism & Traveling Gallery.</h1>
                    <p class="mb-0">
                    Welcome to our Tourism & Traveling Gallery, where wanderlust meets inspiration. Discover a curated collection of stunning visuals and stories that capture the essence of the world's most captivating destinations. From serene landscapes and vibrant cityscapes to hidden gems and cultural marvels, our gallery invites you to explore the beauty and diversity of global travel. Immerse yourself in the richness of each locale, as we celebrate the joy of discovery and the magic of new experiences. Whether you're seeking your next adventure or simply indulging in a virtual escape, our gallery promises to ignite your passion for travel and broaden your horizons.
                    </p>
                </div>
                <div class="tab-class text-center">
                    <ul class="nav nav-pills d-inline-flex justify-content-center mb-5">
                        <li class="nav-item">
                            <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill active" data-bs-toggle="pill" href="#GalleryTab-1">
                                <span class="text-dark" style={{ width: '150px' }}>All</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="d-flex py-2 mx-3 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-2">
                                <span class="text-dark" style={{ width: '150px' }}>Kullu-Manali</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-3">
                                <span class="text-dark" style={{ width: '150px' }}>Rajasthan</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-4">
                                <span class="text-dark" style={{ width: '150px' }}>Gujrat</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-Goa">
                                <span class="text-dark" style={{ width: '150px' }}>Goa</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="GalleryTab-1" class="tab-pane fade show p-0 active">
                            <div class="row g-2">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan.jpg" data-lightbox="gallery-1" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-2.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/mehrangarh-fort-rajasthan.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/mehrangarh-fort-rajasthan.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/kullu-manali.png" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/kullu-manali.png" data-lightbox="gallery-4" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/goa.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/goa.jpg" data-lightbox="gallery-5" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/kullu-manali-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/kullu-manali-2.jpg" data-lightbox="gallery-6" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-jodhpur.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-jodhpur.jpg" data-lightbox="gallery-7" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/taboda-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/taboda-2.jpg" data-lightbox="gallery-8" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/taboda.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/taboda.jpg" data-lightbox="gallery-9" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/gallery-10.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">World Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/gallery-10.jpg" data-lightbox="gallery-10" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="GalleryTab-2" class="tab-pane fade show p-0">
                            <div class="row g-2">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/kullu-manali.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Kullu Manali Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/kullu-manali.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/kullu-manali.png" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Kullu Manali Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/kullu-manali.png" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/kullu-manali-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Kullu Manali Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/kullu-manali-2.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="GalleryTab-3" class="tab-pane fade show p-0">
                            <div class="row g-2">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-3.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-3.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-2.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-4.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-4.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-5.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-5.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/mehrangarh-fort-rajasthan.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/mehrangarh-fort-rajasthan.jpgg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Rajasthan-jodhpur.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Rajasthan Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Rajasthan-jodhpur.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="GalleryTab-4" class="tab-pane fade show p-0">
                            <div class="row g-2">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Gujrat-3.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Gujrat Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Gujrat-3.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Gujrat-4.jpeg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Gujrat Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Gujrat-4.jpeg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Gujrat-5.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Gujrat Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Gujrat-5.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Gujrat-6.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Gujrat Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Gujrat-6.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/Gujrat-7.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Gujrat Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/Gujrat-7.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="GalleryTab-Goa" class="tab-pane fade show p-0">
                            <div class="row g-2">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div class="gallery-item h-100">
                                        <img src="/img/goa.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Goa Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/goa.jpg" data-lightbox="gallery-2" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/goa-3.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Goa Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/goa-3.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/goa-2.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Goa Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/goa-2.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                    <div class="gallery-item h-100">
                                        <img src="/img/goa-5.jpg" class="img-fluid w-100 h-100 rounded" alt="Image" />
                                        <div class="gallery-content">
                                            <div class="gallery-info">
                                                <h5 class="text-white text-uppercase mb-2">Goa Tour</h5>
                                                <a href="#packages" class="btn-hover text-white">View All Place <i class="fa fa-arrow-right ms-2"></i></a>
                                            </div>
                                        </div>
                                        <div class="gallery-plus-icon">
                                            <a href="/img/goa-5.jpg" data-lightbox="gallery-3" class="my-auto"><i class="fas fa-plus fa-2x text-white"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid booking py-5" id='tour'>
                <div class="container py-5">
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-6">
                            <h5 class="section-booking-title pe-3">Booking</h5>
                            <h1 class="text-white mb-4">Online Booking</h1>
                            <p class="text-white mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur maxime ullam esse fuga blanditiis accusantium pariatur quis sapiente, veniam doloribus praesentium? Repudiandae iste voluptatem fugiat doloribus quasi quo iure officia.
                            </p>
                            <p class="text-white mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur maxime ullam esse fuga blanditiis accusantium pariatur quis sapiente, veniam doloribus praesentium? Repudiandae iste voluptatem fugiat doloribus quasi quo iure officia.
                            </p>
                            <a href="#" class="btn btn-light text-primary rounded-pill py-3 px-5 mt-2">Read More</a>
                        </div>
                        <div class="col-lg-6">
                            <h1 class="text-white mb-3">Book A Tour Deals</h1>
                            <p class="text-white mb-4">Get <span class="text-warning">Offers</span> On Your First Adventure Trip With Travela. Get More Deal Offers Here.</p>
                            <form>
                                <div class="row g-3">
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control bg-white border-0" id="name" placeholder="Your Name" />
                                            <label for="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="email" class="form-control bg-white border-0" id="email" placeholder="Your Email" />
                                            <label for="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating date" id="date3" data-target-input="nearest">
                                            <input type="text" class="form-control bg-white border-0" id="datetime" placeholder="Date & Time" data-target="#date3" data-toggle="datetimepicker" />
                                            <label for="datetime">Date & Time</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control bg-white border-0" name="" id="" />
                                            <label for="select1">Destination</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control bg-white border-0" name="" id="" />
                                            <label for="SelectPerson">Persons</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control bg-white border-0" name="" id="" />
                                            <label for="CategoriesSelect">Categories</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <textarea class="form-control bg-white border-0" placeholder="Special Request" id="message" style={{ height: '100px' }}></textarea>
                                            <label for="message" > Special Request</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-primary text-white w-100 py-3" type="submit">Book Now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
            <div className="container-fluid testimonial py-5" id='testimonial'>
                <div className="container py-5">
                    <div className="mx-auto text-center mb-5" style={{ maxWidth: '900px' }}>
                        <h5 className="section-title px-3">Testimonial</h5>
                        <h1 className="mb-0">Our Clients Say!!!</h1>
                    </div>
                    <OwlCarousel className="testimonial-carousel owl-carousel" items={1} loop margin={10} autoplay >
                        <div className="testimonial-item text-center rounded pb-4">
                            <div className="testimonial-comment bg-light rounded p-4">
                                <p className="text-center mb-5">I thank from the bottom of my heart to this company for helping us getting the most amazing deal for my travel need. I have contacted them a week ago and I got the best and lucrative deal which no other company was offering. I must say, everyone should consider getting holiday packages from BHARAT TOURISM.</p>
                            </div>
                            <div className="testimonial-img p-1">
                                <img src="/img/testimonial-1.jpg" className="img-fluid rounded-circle" alt="Image" />
                            </div>
                            <div style={{ marginTop: '-35px' }}>
                                <div className="d-flex justify-content-center">
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item text-center rounded pb-4">
                            <div className="testimonial-comment bg-light rounded p-4">
                                <p className="text-center mb-5">I was looking for a travel agent in the market that can provide amazing tour packages. I am delighted that I joined hands with this travel agent to get the best deals. Its customer care executives are really helpful. I provided my details and requirements for the travel package along with my budget and they suggested me the best package. Thanks!</p>
                            </div>
                            <div className="testimonial-img p-1">
                                <img src="/img/testimonial-2.jpg" className="img-fluid rounded-circle" alt="Image" />
                            </div>
                            <div style={{ marginTop: '-35px' }}>
                                <h5 className="mb-0">Rakesh Sharma</h5>
                                <div className="d-flex justify-content-center">
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item text-center rounded pb-4">
                            <div className="testimonial-comment bg-light rounded p-4">
                                <p className="text-center mb-5">It is a registered travel agent that provides interesting packages for different destinations and purposes. I have booked my tour package with them. I was surprised to see the wide options it has for budget to luxury clients. I would recommend this company to every acquaintance of mine.</p>
                            </div>
                            <div className="testimonial-img p-1">
                                <img src="/img/testimonial-3.jpg" className="img-fluid rounded-circle" alt="Image" />
                            </div>
                            <div style={{ marginTop: '-35px' }}>
                                <h5 className="mb-0">Aditya Rastogi</h5>
                                <div className="d-flex justify-content-center">
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item text-center rounded pb-4">
                            <div className="testimonial-comment bg-light rounded p-4">
                                <p className="text-center mb-5">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis nostrum cupiditate, eligendi repellendus saepe illum earum architecto dicta quisquam quasi porro officiis. Vero reiciendis,</p>
                            </div>
                            <div className="testimonial-img p-1">
                                <img src="/img/testimonial-4.jpg" className="img-fluid rounded-circle" alt="Image" />
                            </div>
                            <div style={{ marginTop: '-35px' }}>
                                <h5 className="mb-0">John Abraham</h5>
                                <p className="mb-0">New York, USA</p>
                                <div className="d-flex justify-content-center">
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                    <i className="fas fa-star text-primary"></i>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
            <div class="container-fluid bg-breadcrumb">
                <div class="container text-center py-5" style={{ maxWidth: '900px' }}>
                    <h3 class="text-white display-3 mb-4">Contact Us</h3>

                </div>
            </div>
            <div class="container-fluid contact bg-light py-5" id='contact'>
                <div class="container py-5">
                    <div class="mx-auto text-center mb-5" style={{ maxWidth: '900px' }}>
                        <h5 class="section-title px-3">Contact Us</h5>
                        <h1 class="mb-0">Contact For Any Query</h1>
                    </div>
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-4">
                            <div class="bg-white rounded p-4">
                                <div class="text-center mb-4">
                                    <i class="fa fa-map-marker-alt fa-3x text-primary"></i>
                                    <h4 class="text-primary">Address</h4>
                                    <p class="mb-0"> New Delhi, India - 000000</p>
                                </div>
                                <div class="text-center mb-4">
                                    <i class="fa fa-phone-alt fa-3x text-primary mb-3"></i>
                                    <h4 class="text-primary">Mobile</h4>
                                    <p class="mb-0">+91 9832785010</p>
                                </div>

                                <div class="text-center">
                                    <i class="fa fa-envelope-open fa-3x text-primary mb-3"></i>
                                    <h4 class="text-primary">Email</h4>
                                    <p class="mb-0">bharattravelt@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <h2 class="mb-2">Send us a message</h2>
                           
                            <form>
                                <div class="row g-3">
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control border-0" id="name" placeholder="Your Name" />
                                            <label for="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="email" class="form-control border-0" id="email" placeholder="Your Email" />
                                            <label for="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control border-0" id="subject" placeholder="Subject" />
                                            <label for="subject">Subject</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <textarea class="form-control border-0" placeholder="Leave a message here" id="message" style={{ height: '160px' }}></textarea>
                                            <label for="message">Message</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-12">
                            <div class="rounded">
                                <iframe class="rounded w-100"
                                    style={{ height: '450px' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.33750346623!2d-73.97968099999999!3d40.6974881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1694259649153!5m2!1sen!2sbd"
                                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid bg-contact py-5" id='subscribe'>
                <div class="container text-center py-5">
                    <div class="mx-auto text-center" style={{ maxWidth: ' 900px' }}>
                        <h5 class="subscribe-title px-3">Subscribe</h5>
                        <h1 class="text-white mb-4">Our Newsletter</h1>
                        <p class="text-white mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum tempore nam, architecto doloremque velit explicabo? Voluptate sunt eveniet fuga eligendi! Expedita laudantium fugiat corrupti eum cum repellat a laborum quasi.
                        </p>
                        <div class="position-relative mx-auto">
                            <input class="form-control border-primary rounded-pill w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                            <button type="button" class="btn btn-primary rounded-pill position-absolute top-0 end-0 py-2 px-4 mt-2 me-2">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePage